<template>
	<div class="user-info-box">
		<a-tabs default-active-key="1" @change="callback">
			<a-tab-pane key="1" tab="个人资料">
				<div style="width: 500px;padding-top: 20px;">
					<a-form-model ref="ruleForm1" :model="form" :rules="rules" :label-col="labelCol"
						:wrapper-col="wrapperCol">
						<a-form-model-item prop="real_name" label="头像">
							<a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
								:customRequest="customRequest" :before-upload="beforeUpload">
								<img style="width: 100px;" v-if="form.avatar" :src="form.avatar" alt="avatar" />
								<div v-else>
									<a-icon :type="loading ? 'loading' : 'plus'" />
									<div class="ant-upload-text">
										Upload
									</div>
								</div>
							</a-upload>
						</a-form-model-item>
						<a-form-model-item style="margin-top: -20px;" ref="real_name" prop="real_name" label="真实姓名">
							<a-input v-model="form.real_name"  placeholder="请输入真实姓名"/>
						</a-form-model-item>
						<a-form-model-item ref="contact" prop="contact" label="联系方式">
							<a-input v-model="form.contact"  placeholder="请输入联系方式"/>
						</a-form-model-item>
						<a-form-model-item ref="mobile" prop="mobile" label="手机号码">
							<a-input v-model="form.mobile"  placeholder="请输入手机号码"/>
						</a-form-model-item>
						<a-form-model-item ref="mobile">
							<a-button type="primary" style="margin-left: 124px;" @click="save">
								保存
							</a-button>
						</a-form-model-item>
					</a-form-model>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="安全中心" force-render>
				<div style="width: 500px;padding-top: 20px;">
					<a-form-model ref="ruleForm" :model="passwordForm" :rules="passwordRules" :label-col="labelCol"
						:wrapper-col="wrapperCol">
						<a-form-model-item ref="name" prop="password" label="密码">
							<a-input-password v-model="passwordForm.password" placeholder="请输入密码"  />
						</a-form-model-item>
						<a-form-model-item ref="name" prop="npassword" label="确认密码">
							<a-input-password v-model="passwordForm.npassword" placeholder="请输入确认密码"  />
						</a-form-model-item>
						<a-form-model-item ref="name">
							<div style="margin-left: 124px;">
								<a-button type="primary" @click="update">
									确认
								</a-button>
							</div>
						</a-form-model-item>
					</a-form-model>
				</div>
			</a-tab-pane>
			<a-tab-pane key="3" tab="绑定微信">
				<div style="padding-left: 20px;">
					<img :src="form.qrcode" style="
    width: 300px;
    height: 300px;
    display:  block;
    border: 1px solid #ccc
" alt="">
					<div style="width:300px;margin-top: 20px;text-align: center;">
						
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>


<script>
	import http from '@/http'

	export default {
		data() {
			return {
				formInline: {
					user: '',
					password: '',
				},
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				form: {
					"avatar": "",
					"real_name": "",
					"contact": "",
					"mobile": "",
					"qrcode": ""
				},
				rules: {
					real_name: [{
							required: true,
							message: '请输入真实姓名',
							trigger: 'blur'
						}
					],
					contact: [{
							required: true,
							message: '请输入联系方式',
							trigger: 'blur'
						}
					],
					mobile: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						}
					],
				},
				
				passwordForm: {
					password: '',
					npassword: ''
				},
				passwordRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, { min: 6, max: 20, message: '密码最小长度为6位数', trigger: 'blur' }],
					npassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}, { min: 6, max: 20, message: '密码最小长度为6位数', trigger: 'blur' }],
				}
			};
		},
		mounted() {
			this.getInit()
		},
		methods: {
			customRequest(data) {
				let formData = new FormData()
				formData.append('file', data.file)
				http({
					method: 'post',
					url: '/api.config.upload.image/',
					ContentType: { 'Content-Type': 'multipart/form-data' },
					data: {
						isFile: true,
						file: data.file
					}
				}).then((res) => {
					this.form.avatar = res.data
				})
			},
			getInit () {
				http({
					method: 'post',
					url: '/api.admin.mydetail/',
					data: {
				
					}
				}).then((res) => {
					this.form = res.data
				})
			},
			handleSubmit(e) {
			},

			save() {
				this.$refs.ruleForm1.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.admin.updatemydata/',
							data: {
								real_name: this.form.real_name,
								contact: this.form.contact,
								mobile: this.form.mobile,
								avatar: this.form.avatar,
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('更新个人资料成功');
								this.getInit()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});
				
			},
			update () {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						if (this.passwordForm.password !== this.passwordForm.npassword) {
							this.$message.warning('两次密码不相同，请检查');
							return
						}
						let newData = {
							...this.passwordForm
						}
						http({
							method: 'post',
							url: '/api.admin.updatemypwd/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('修改密码成功');
								this.getInit()
								this.modelPasswordVisible = false;
								this.passwordForm = {
									admin_id: 0,
									password: '',
									npassword: ''
								}
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});
			}
		},
	};
</script>

<style lang="less" scoped>
	.user-info-box {
		background-color: #fff;
		height: 600px;
	}
</style>
